<template>
  <div>
      <ed-modelo :intTipoModeloId="1321" :key="$root.$session.versao" :intId="this.$route.params.intId"/>
  </div>
</template>

<script>
import EdModelo from "@/components/documento/modelo/editar";
export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdModelo },
  mounted() {
    if (!this.$route.params.intId) {
      this.$router.push({ name: "consulta.prontuario.modelo" });
    }
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>